import { Inject, Injectable, Optional } from '@angular/core';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class CustomLuxonDateAdapter extends LuxonDateAdapter {
  constructor(@Optional() @Inject(MAT_DATE_LOCALE) private dateLocale: string) {
    super(dateLocale);
  }

  override getFirstDayOfWeek(): number {
    return 1;
  }

  override getDayOfWeekNames(): string[] {
    const adjustedStartOfWeek = DateTime.local().setLocale(this.dateLocale).startOf('week').minus({ days: 1 });

    return Array.from({ length: 7 }, (_, i) => {
      const day = adjustedStartOfWeek.plus({ days: i });
      return day.toLocaleString({ weekday: 'short' });
    });
  }
}
