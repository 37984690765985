import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export class CustomInputValidators {
  static hasCapitalLetter(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return /[A-Z]/.test(control.value) ? null : { noCapitalLetter: true };
    };
  }

  static hasSmallLetter(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return /[a-z]/.test(control.value) ? null : { noSmallLetter: true };
    };
  }

  static hasSpecialCharacter(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return /[!@#$%^&*(),.?":{}|<>]/.test(control.value) ? null : { noSpecialCharacter: true };
    };
  }

  static hasNumber(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return /\d/.test(control.value) ? null : { noNumber: true };
    };
  }

  static hasMinimumLength(length = 8): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value && control.value.length >= length ? null : { minLength: { requiredLength: length } };
    };
  }

  static isPhoneNumber(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        return null;
      }

      const regExp = /^[0-9]{7,11}$/;
      return regExp.test(value) ? null : { isPhoneNumber: true };
    };
  }

  static isPhoneNumberOptionalPlus(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        return null;
      }

      const regExp = /^\+?[0-9]{9,13}$/;
      return regExp.test(value) ? null : { isPhoneNumber: true };
    };
  }

  static isNumbers(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return /^\d+$/.test(control.value) ? null : { isNumbers: true };
    };
  }

  static atLeastOneRequiredValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control instanceof FormGroup) {
        const controls = control.controls;
        const hasAtLeastOneValue = Object.keys(controls).some((key) => {
          const control = controls[key];
          return (
            control.value !== null &&
            control.value !== undefined &&
            control.value !== '' &&
            control.value !== false &&
            !control.pristine
          );
        });

        return hasAtLeastOneValue ? null : { atLeastOneRequired: true };
      }

      return null;
    };
  }

  static fileValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const files: File[] = control.value;
      if (!files || files.length === 0) {
        return { invalidFile: true };
      }
      const isValid = files.every((file) => {
        return file.size <= 10 * 1024 * 1024 && file.type.startsWith('image/');
      });

      return !isValid ? { invalidFile: true } : null;
    };
  }

  static controlsMustMatch(controlName: string, matchingControlName: string, errorName: string): ValidatorFn {
    return (group: AbstractControl) => {
      const control = group.get(controlName);
      const matchingControl = group.get(matchingControlName);

      if (!control || !matchingControl) {
        return null;
      }

      if (matchingControl.errors && !matchingControl.errors[errorName]) {
        return null;
      }

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ [errorName]: true });
      } else {
        matchingControl.setErrors(null);
      }
      return null;
    };
  }
}
