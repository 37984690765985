import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModuleConfig } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export class TranslateConfigUtil {
  static createTranslateConfig(): TranslateModuleConfig {
    const config = {
      isolate: true,
      defaultLanguage: 'de',
      loader: {
        provide: TranslateLoader,
        useFactory: this.TranslateLoaderFactory,
        deps: [HttpClient],
      },
    };
    return config;
  }

  private static TranslateLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http, `assets/i18n/`, '.json');
  }
}
