import { animate, AnimationTriggerMetadata, style, transition, trigger } from '@angular/animations';

export class Animations {
  static createFadeInOutAnimation(duration: string): AnimationTriggerMetadata {
    return trigger('fadeInOut', [
      transition(':enter', [style({ opacity: 0 }), animate(`${duration} ease-in`, style({ opacity: 1 }))]),
      transition(':leave', [animate(`${duration} ease-out`, style({ opacity: 0 }))]),
    ]);
  }
}
