export class DownloadFile {
  static downloadFileFromBlob(blob: Blob, fileName: string): void {
    const downloadURL = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(downloadURL);
  }
}
