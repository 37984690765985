import { CountryCode, CountryPhoneCode } from '@enx/shared/util/enums';

export class EnumHelper {
  static getEnumValueFromCaseInsensitiveCompare<T extends object>(enumObj: T, value: string): T[keyof T] | undefined {
    const normalizedInput = value.toLowerCase();

    for (const [key, enumValue] of Object.entries(enumObj)) {
      if (typeof enumValue === 'string' && enumValue.toLowerCase() === normalizedInput) {
        return enumObj[key as keyof T];
      }
    }

    return undefined;
  }

  static existsInEnum<T extends object>(enumObj: T, value: string): boolean {
    return Object.values(enumObj)
      .map((o) => o.toLowerCase())
      .includes(value?.toLowerCase() as T[keyof T]);
  }

  static getCountryPhoneCode(countryCode: CountryCode): CountryPhoneCode {
    switch (countryCode) {
      case CountryCode.AUSTRIA:
        return CountryPhoneCode.AUSTRIA;

      case CountryCode.GERMANY:
      default:
        return CountryPhoneCode.GERMANY;
    }
  }
}
